// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ayuda-help-icon {
  width: 1.6em;
  margin-left: 0;
  margin-right: 0.5em;
}
.ayuda-help-text {
  font-size: 1.1em;
}

.ayuda-help-link {
  text-decoration: none;
  color: #2b363b;
  display: inline;
}

.ayuda-help-phone {
  font-size: 1.2em;
  font-weight: 700;
}

/* BREAKPOINT ESCRITORIO */
@media (min-width: 992px) {
  .ayuda-help-icon {
    margin-right: 0.5em;
    margin-left: 0;
    width: 1.4em;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ayuda/ayuda.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,cAAc;EACd,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA,0BAA0B;AAC1B;EACE;IACE,mBAAmB;IACnB,cAAc;IACd,YAAY;EACd;AACF","sourcesContent":[".ayuda-help-icon {\n  width: 1.6em;\n  margin-left: 0;\n  margin-right: 0.5em;\n}\n.ayuda-help-text {\n  font-size: 1.1em;\n}\n\n.ayuda-help-link {\n  text-decoration: none;\n  color: #2b363b;\n  display: inline;\n}\n\n.ayuda-help-phone {\n  font-size: 1.2em;\n  font-weight: 700;\n}\n\n/* BREAKPOINT ESCRITORIO */\n@media (min-width: 992px) {\n  .ayuda-help-icon {\n    margin-right: 0.5em;\n    margin-left: 0;\n    width: 1.4em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
