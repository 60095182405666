import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Container,
  FloatingLabel,
  Form,
  Modal,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosApi from "../../axios/axios-api";
import { LogoPetroprix } from "./LogoPetroprix";
import Feedback from "react-bootstrap/esm/Feedback";
import { useSelector } from "react-redux";
import password_hide_icon from "../../assets/images/password-hide-icon.svg";
import password_show_icon from "../../assets/images/password-show-icon.svg";
import { useDispatch } from "react-redux";
import { ValidateToken } from "../../helpers/validateToken";
import { startLogout } from "../../store/auth";
import { MiniLoader } from "./MiniLoader";

const URL_PUT_CAMBIAR_PASSWORD = "cliente/cambiarContrasenia";

export function ModalCambiarPassword(props) {
  const { iduser } = props;
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [showPasswordCurrent, setShowPasswordCurrent] = useState(false);
  const [iconShowPasswordCurrent, setIconShowPasswordCurrent] =
    useState(password_hide_icon);

  const [showPassword, setShowPassword] = useState(false);
  const [iconShowPassword, setIconShowPassword] = useState(password_hide_icon);

  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
  const [iconShowPasswordRepeat, setIconShowPasswordRepeat] =
    useState(password_hide_icon);

  useEffect(() => {
    setShowPasswordCurrent(false);
    setShowPassword(false);
    setShowPasswordRepeat(false);
    setIconShowPasswordCurrent(password_hide_icon);
    setIconShowPassword(password_hide_icon);
    setIconShowPasswordRepeat(password_hide_icon);
    setValidated(false);
  }, [props.show]);

  const validatePassword = (password) => {
    // Valida que la contraseña tenga entre 8 y 15 caracteres, una letra mayúscula, una letra minúscula y un número
    const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,15}$/;
    return re.test(password);
  };

  const changePassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    let currentPassword = document.getElementById("current-password");
    let newPassword = document.getElementById("new-password");
    let passwordRepeat = document.getElementById("password-repeat");

    let passwordData = {
      current: false,
      new: false,
      repeat: false,
    };

    if (currentPassword.value === "") {
      currentPassword.setCustomValidity(" ");
      passwordData.current = false;
    } else {
      currentPassword.setCustomValidity("");
      passwordData.current = true;
    }

    if (!validatePassword(newPassword.value)) {
      newPassword.setCustomValidity(" ");
      document.getElementById("new-password-feedback").innerHTML = t(
        "header.modal-password.form.new-password.invalid"
      );
      passwordData.new = false;
    } else if (newPassword.value === currentPassword.value) {
      newPassword.setCustomValidity(" ");
      document.getElementById("new-password-feedback").innerHTML = t(
        "header.modal-password.form.new-password-repeat.invalid"
      );
      passwordData.new = false;
    } else {
      newPassword.setCustomValidity("");
      document.getElementById("new-password-feedback").innerHTML = "";
      passwordData.new = true;
    }

    if (passwordRepeat.value !== newPassword.value) {
      passwordRepeat.setCustomValidity(" ");
      passwordData.repeat = false;
    } else {
      passwordRepeat.setCustomValidity("");
      passwordData.repeat = true;
    }

    setValidated(true);

    if (!passwordData.current || !passwordData.new || !passwordData.repeat) {
      setLoading(false);
      return;
    }

    const data = {
      pass: currentPassword.value,
      new_pass: newPassword.value,
      new_pass_repeat: passwordRepeat.value,
    };

    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.put(URL_PUT_CAMBIAR_PASSWORD, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response?.data?.code === 200) {
          // cerramos el modal
          props.onHide();
          toast(t("header.modal-password.form.result.ok"), {
            icon: <LogoPetroprix />,
          });
          setLoading(false);
        } else {
          toast(response.data.message, {
            icon: <LogoPetroprix />,
          });
          setLoading(false);
        }
      } else {
        setLoading(false);
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleShowPasswordCurrent = () => {
    setShowPasswordCurrent(!showPasswordCurrent);
    showPasswordCurrent
      ? setIconShowPasswordCurrent(password_hide_icon)
      : setIconShowPasswordCurrent(password_show_icon);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
    showPassword
      ? setIconShowPassword(password_hide_icon)
      : setIconShowPassword(password_show_icon);
  };

  const handleShowPasswordRepeat = () => {
    setShowPasswordRepeat(!showPasswordRepeat);
    showPasswordRepeat
      ? setIconShowPasswordRepeat(password_hide_icon)
      : setIconShowPasswordRepeat(password_show_icon);
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Container className="py-5">
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("header.modal-password.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="primary-login">
            {t("header.modal-password.form.label.info.1")}
            <b>{t("header.modal-password.form.label.info.2")}</b>
          </Alert>
          <Form
            autoComplete="off"
            onSubmit={(e) => changePassword(e)}
            noValidate
            validated={validated}
          >
            <FloatingLabel
              label={t("header.modal-password.form.password")}
              className="mt-3"
            >
              <Form.Control
                type={showPasswordCurrent ? "text" : "password"}
                id="current-password"
                placeholder={t("header.modal-password.form.password")}
                required
              />
              <span
                toggle="#password-field"
                className="toggle-password"
                onClick={handleShowPasswordCurrent}
              >
                <img src={iconShowPasswordCurrent} className="passwordIcon" />
              </span>
              <Feedback type="invalid">
                {t("header.modal-password.form.password.invalid")}
              </Feedback>
            </FloatingLabel>

            <FloatingLabel
              label={t("header.modal-password.form.new-password")}
              className="mt-3"
            >
              <Form.Control
                type={showPassword ? "text" : "password"}
                id="new-password"
                placeholder={t("header.modal-password.form.new-password")}
                required
                minLength="8"
                maxLength="15"
              />
              <span
                toggle="#password-field"
                className="toggle-password"
                onClick={handleShowPassword}
              >
                <img src={iconShowPassword} className="passwordIcon" />
              </span>
              <Feedback type="invalid" id="new-password-feedback">
                {t("header.modal-password.form.new-password.invalid")}
              </Feedback>
            </FloatingLabel>

            <FloatingLabel
              label={t("header.modal-password.form.repeat-password")}
              className="mt-3"
            >
              <Form.Control
                type={showPasswordRepeat ? "text" : "password"}
                id="password-repeat"
                placeholder={t("header.modal-password.form.repeat-password")}
                required
                minLength="8"
                maxLength="15"
              />
              <span
                toggle="#password-field"
                className="toggle-password"
                onClick={handleShowPasswordRepeat}
              >
                <img src={iconShowPasswordRepeat} className="passwordIcon" />
              </span>
              <Feedback type="invalid">
                {t("header.modal-password.form.repeat-password.invalid")}
              </Feedback>
            </FloatingLabel>

            <div className="d-grid gap-2 mt-5">
              <Button
                variant="outline-primary"
                size="lg"
                onClick={() => props.onHide(false)}
              >
                {t("header.modal-password.form.cancel-btn")}
              </Button>
              <Button
                variant="primary"
                size="lg"
                onClick={(e) => changePassword(e)}
                id="btn-modal-password-edit"
                disabled={loading}
              >
                {loading ? (
                  <MiniLoader />
                ) : (
                  t("header.modal-password.form.save-btn")
                )}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Container>
    </Modal>
  );
}
