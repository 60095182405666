import { useCallback, useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import help_img from "../../assets/images/help-img.svg";
import phone_icon from "../../assets/images/phone-icon.svg";
import email_icon from "../../assets/images/email-icon.svg";
import "./ayuda.css";
import { Menu } from "../ui/Menu";
import { Header } from "../ui/Header";
import { useTranslation } from "react-i18next";
import axiosApi from "../../axios/axios-api";
import { LoaderPetroprix } from "../ui/LoaderPetroprix";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { startLogout } from "../../store/auth";
import { ValidateToken } from "../../helpers/validateToken";
import { formatTelefono } from "../../helpers/formatTelefono";
import { use } from "i18next";
import { set } from "date-fns";

const URL_GET_VEHICULOS_FLOTA = "flotas/vehiculos/";

export const AyudaScreen = () => {
  const { token, nombre, apellido, email_informacion, telefono } = useSelector(
    (state) => state.auth
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [tel, setTel] = useState(
    telefono !== null ? telefono : t("ayuda.telefono")
  );

  const [mail, setMail] = useState(
    email_informacion !== null ? email_informacion : t("ayuda.email")
  );

  return (
    <>
      <Menu />
      <Container className="content-page">
        <Header name={nombre + " " + apellido} />
        <Container className="mt-1">
          <Row className="content-section">
            <Row className="no-flota mt-1 mb-5">
              <Col xs={12} lg={{ span: 8, offset: 2 }}>
                <Image
                  src={help_img}
                  fluid="true"
                  className="flotas-image-info"
                />
                {/* <h2>{t("flotas.no-flotas.h2")}</h2> */}
                <h2>{t("ayuda.h2")}</h2>
                <div className="d-grid gap-2">
                  <Row>
                    <Col xs={12} lg={{ span: 8, offset: 2 }}>
                      <p>{t("ayuda.p")}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      md={{ span: 4, offset: 2 }}
                      className="mt-3 mt-md-0"
                    >
                      <a href={`tel:${tel}`} className="ayuda-help-link">
                        <img
                          src={phone_icon}
                          alt={t("ayuda.help.phone")}
                          className="ayuda-help-icon"
                        />
                        <span className="ayuda-help-phone">
                          {formatTelefono(tel)}
                        </span>
                      </a>
                    </Col>
                    <Col xs={12} md={4} className="mt-3 mt-md-0">
                      <a href={`mailto:${mail}`} className="ayuda-help-link">
                        <img
                          src={email_icon}
                          alt={t("ayuda.help.phone")}
                          className="ayuda-help-icon"
                        />
                        <span className="ayuda-help-phone">{mail}</span>
                      </a>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Row>
        </Container>
      </Container>
    </>
  );
};
