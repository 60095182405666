import {
  Alert,
  Button,
  Col,
  Container,
  Dropdown,
  Row,
  Table,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axiosApi from "../../axios/axios-api";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { ModalBorrarVehiculo } from "./ModalBorrarVehiculo";
import { ModalEditarVehiculo } from "./ModalEditarVehiculo";
import edit_icon from "../../assets/images/edit-icon.svg";
import delete_icon from "../../assets/images/delete-icon.svg";
import info_icon from "../../assets/images/info-icon-b.svg";
import add_flota_icon from "../../assets/images/add-flota-icon.svg";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { LoaderPetroprix } from "../ui/LoaderPetroprix";
import { ValidateToken } from "../../helpers/validateToken";
import { startLogout } from "../../store/auth";

const URL_GET_VEHICULOS = "vehiculos";

export const VehiculosCard = ({
  bloqueada,
  setLoadingVehicles,
  setNumVehiculos,
  checkingStatus,
}) => {
  const navigate = useNavigate();
  const { token, flotas, flotaActiva } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [vehiculos, setVehiculos] = useState([]);
  const [apiData, setApiData] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [buttonBlocked, setButtonBlocked] = useState(true);
  const [modalEditar, setModalEditar] = useState({
    show: false,
    id: null,
    matricula: null,
    tipo: null,
    tipo_vehiculo: null,
    factura: null,
  });
  const [modalBorrar, setModalBorrar] = useState({
    show: false,
    id: null,
    matricula: null,
    tipo: null,
    tipo_vehiculo: null,
    factura: null,
  });

  const handleAnadir = () => {
    if (ValidateToken()) {
      navigate("/nuevo-vehiculo");
    } else {
      dispatch(startLogout("expired"));
    }
  };

  const { t } = useTranslation();

  const fetchVehicleData = useCallback(async () => {
    setLoadingData(true);
    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.get(URL_GET_VEHICULOS, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response?.data?.code === 200) {
          let data = response.data.data;
          setNumVehiculos(data.length);
          setApiData(data);
          orderData(true, 0, data)();
          setLoadingData(false);
          setLoadingVehicles(false);
        } else {
          setLoadingData(false);
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      console.error(error);
      setLoadingData(false);
    }
  }, []);

  const orderData =
    (asc, index, vehiculos = null) =>
    () => {
      let data;
      if (!vehiculos) {
        data = [...apiData];
      } else {
        data = [...vehiculos];
      }

      switch (index) {
        case 0:
          index = "matricula";
          break;
        case 1:
          index = "tipo";
          break;
        case 2:
          index = "factura";
          break;
        default:
          break;
      }

      if (asc) {
        data.sort((a, b) => {
          if (a[index] < b[index]) {
            return -1;
          }
          if (a[index] > b[index]) {
            return 1;
          }
          return 0;
        });
      } else {
        data.sort((a, b) => {
          if (a[index] > b[index]) {
            return -1;
          }
          if (a[index] < b[index]) {
            return 1;
          }
          return 0;
        });
      }
      setVehiculos(data);
    };

  useEffect(() => {
    fetchVehicleData();
  }, [fetchVehicleData]);

  useEffect(() => {
    if (apiData) {
      orderData(true, 0)();
    }
  }, [apiData]);

  return (
    <>
      <Row style={{ paddingRight: 0 }}>
        <Col xs={7}>
          <h2>
            {t("datos.vehiculos.list.h2")}{" "}
            {bloqueada.bloqueada && bloqueada.tipo !== null && (
              <img
                src={info_icon}
                alt="info"
                className="info-img-icon-blocked"
              />
            )}
          </h2>
        </Col>
        <Col className="section-header-btn" style={{ paddingRight: 0 }}>
          <Button
            variant="primary"
            onClick={handleAnadir}
            disabled={checkingStatus || bloqueada.bloqueada ? true : false}
          >
            + {t("datos.vehiculos.list.add-btn")}
          </Button>
        </Col>
      </Row>
      {loadingData ? (
        <LoaderPetroprix type="small" position="absolute" />
      ) : vehiculos?.length !== 0 ? (
        <>
          <Row>
            <Table hover={false} className="datos-vehiculos">
              <thead>
                <tr>
                  <th style={{ width: "25%" }}>
                    {t("datos.vehiculos.list.table.vehiculo")}
                    <span className="order-table-icons float-end">
                      <AiFillCaretDown onClick={orderData(false, 0)} />
                      <AiFillCaretUp onClick={orderData(true, 0)} />
                    </span>
                  </th>
                  <th style={{ width: "32%" }}>
                    {t("datos.vehiculos.list.table.combustible")}
                    <span className="order-table-icons float-end">
                      <AiFillCaretDown onClick={orderData(false, 1)} />
                      <AiFillCaretUp onClick={orderData(true, 1)} />
                    </span>
                  </th>
                  <th style={{ width: "20%" }}>
                    {t("datos.vehiculos.list.table.factura")}
                    <span className="order-table-icons float-end">
                      <AiFillCaretDown onClick={orderData(false, 2)} />
                      <AiFillCaretUp onClick={orderData(true, 2)} />
                    </span>
                  </th>
                  <th></th>
                </tr>
              </thead>
            </Table>
          </Row>

          <Container className="datos-vehiculo-list">
            {vehiculos?.map((vehiculo) => (
              <Row key={vehiculo.id} id={"vehiculo-" + vehiculo.id}>
                <Container className="datos-vehiculo">
                  <Col className="datos-start">
                    <Row>
                      <span className="datos-matricula">
                        {vehiculo.matricula}
                      </span>
                    </Row>
                    <Row>
                      <span className="datos-tipo-vehiculo">
                        {vehiculo.tipo_vehiculo === "0"
                          ? "Turismo"
                          : vehiculo.tipo_vehiculo === "1"
                          ? "Motocicleta"
                          : "Camión"}
                      </span>
                    </Row>
                  </Col>
                  <Col className="datos-content">
                    {vehiculo.tipo === "Gasolina" ? (
                      <Alert variant="success" className="combustible">
                        Gasolina
                      </Alert>
                    ) : (
                      <Alert variant="warning" className="combustible">
                        Diésel
                      </Alert>
                    )}
                  </Col>
                  <Col className="datos-end">
                    <span className="datos-factura">
                      {vehiculo.factura === 0 ? "No" : "Si"}
                    </span>
                  </Col>
                  <Col className="datos-mas d-none d-sm-block d-md-none">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="secondary"
                        id="dropdown-basic"
                      ></Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            setModalEditar({
                              show: true,
                              id: vehiculo.id,
                              matricula: vehiculo.matricula,
                              tipo: vehiculo.tipo,
                              tipo_vehiculo: vehiculo.tipo_vehiculo,
                              factura: vehiculo.factura,
                            })
                          }
                        >
                          {t("datos.vehiculos.card.menu.editar")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            setModalBorrar({
                              show: true,
                              id: vehiculo.id,
                              matricula: vehiculo.matricula,
                              tipo: vehiculo.tipo,
                              tipo_vehiculo: vehiculo.tipo_vehiculo,
                              factura: vehiculo.factura,
                            })
                          }
                        >
                          {t("datos.vehiculos.card.menu.borrar")}
                        </Dropdown.Item>
                        {flotas === "1" && (
                          <Dropdown.Item
                            onClick={() => {
                              if (ValidateToken()) {
                                navigate("/flotas/nuevo-vehiculo", {
                                  state: {
                                    id: vehiculo.id,
                                    matricula: vehiculo.matricula,
                                    tipo: vehiculo.tipo,
                                    tipo_vehiculo: vehiculo.tipo_vehiculo,
                                    factura: vehiculo.factura,
                                    personal: true,
                                  },
                                });
                              } else {
                                dispatch(startLogout("expired"));
                              }
                            }}
                          >
                            {t("datos.vehiculos.card.menu.add-flota")}
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>

                  <Col className="datos-mas-btns d-none d-lg-block">
                    <Button variant="light">
                      <img
                        src={edit_icon}
                        alt={t("datos.vehiculos.card.menu.editar")}
                        className="table-btn edit-icon-btn"
                        onClick={() =>
                          setModalEditar({
                            show: true,
                            id: vehiculo.id,
                            matricula: vehiculo.matricula,
                            tipo: vehiculo.tipo,
                            tipo_vehiculo: vehiculo.tipo_vehiculo,
                            factura: vehiculo.factura,
                          })
                        }
                      />
                    </Button>
                    <Button variant="light">
                      <img
                        src={delete_icon}
                        alt={t("datos.vehiculos.card.menu.borrar")}
                        className="table-btn delete-icon-btn"
                        onClick={() =>
                          setModalBorrar({
                            show: true,
                            id: vehiculo.id,
                            matricula: vehiculo.matricula,
                            tipo: vehiculo.tipo,
                            tipo_vehiculo: vehiculo.tipo_vehiculo,
                            factura: vehiculo.factura,
                          })
                        }
                      />
                    </Button>
                    {flotas === "1" && (
                      <Button variant="light">
                        <img
                          src={add_flota_icon}
                          alt={t("datos.vehiculos.card.menu.add-flota")}
                          className="table-btn table-btn add-flota-icon-btn"
                          onClick={() => {
                            if (ValidateToken()) {
                              navigate("/flotas/nuevo-vehiculo", {
                                state: {
                                  id: vehiculo.id,
                                  matricula: vehiculo.matricula,
                                  tipo: vehiculo.tipo,
                                  tipo_vehiculo: vehiculo.tipo_vehiculo,
                                  factura: vehiculo.factura,
                                  personal: true,
                                },
                              });
                            } else {
                              dispatch(startLogout("expired"));
                            }
                          }}
                        />
                      </Button>
                    )}
                  </Col>
                </Container>
              </Row>
            ))}
          </Container>
        </>
      ) : (
        <Row>
          <div className="text-center mt-3">
            <h6>{t("datos.vehiculos.list.no-results")}</h6>
          </div>
        </Row>
      )}

      <ModalEditarVehiculo
        show={modalEditar.show}
        onHide={(edited = false) => {
          if (edited) {
            fetchVehicleData();
          }
          setModalEditar({ ...modalEditar, show: false });
        }}
        id={modalEditar.id}
        matricula={modalEditar.matricula}
        tipo={modalEditar.tipo}
        tipo_vehiculo={modalEditar.tipo_vehiculo}
        factura={modalEditar.factura}
      />

      <ModalBorrarVehiculo
        show={modalBorrar.show}
        onHide={(deleted = false) => {
          if (deleted) {
            let vehiculo = vehiculos.filter(
              (vehicle) => vehicle.id !== modalBorrar.id
            );
            if (vehiculo !== vehiculos) {
              let fila = document.getElementById("vehiculo-" + modalBorrar.id);
              fila.classList.add("animate__animated");
              fila.classList.add("animate__lightSpeedOutLeft");
              fila.addEventListener("animationend", () => {
                fila.classList.remove("animate__animated");
                fila.classList.remove("animate__lightSpeedOutLeft");
                fila.classList.add("d-none");
              });
              setNumVehiculos(vehiculo.length);
            }
          }
          setModalBorrar({ ...modalBorrar, show: false });
        }}
        id={modalBorrar.id}
        matricula={modalBorrar.matricula}
      />
    </>
  );
};
