import { Routes, Route, Navigate } from "react-router-dom";
import { DatosScreen } from "../components/datos/DatosScreen";
import { EditarDatosFacturacion } from "../components/datos/EditarDatosFacturacion";
import { GasolineraList } from "../components/datos/GasolineraList";
import { NuevoVehiculo } from "../components/datos/NuevoVehiculo";
import { EstadisticasScreen } from "../components/estadisticas/EstadisticasScreen";
import { FacturasScreen } from "../components/facturas/FacturasScreen";
import { Error404Screen } from "../components/feedback/Error404Screen";
import { FlotasScreen } from "../components/flotas/FlotasScreen";
import { NotificacionesScreen } from "../components/flotas/NotificacionesScreen";
import { RegistroFlotaScreen } from "../components/flotas/registro-flota/RegistroFlotaScreen";
import { RegistroVehiculoScreen } from "../components/flotas/registro-vehiculo/RegistroVehiculoScreen";
import { EstadoScreen } from "../components/pago/EstadoScreen";
import { HistorialPagos } from "../components/pago/HistorialPagos";
import { MetodosPagoScreen } from "../components/pago/MetodosPagoScreen";
import { NuevaTarjetaScreen } from "../components/pago/NuevaTarjetaScreen";
import { PromocionesScreen } from "../components/promociones/PromocionesScreen";
import { RepostajesScreen } from "../components/repostajes/RepostajesScreen";
import { AgregarSaldoScreen } from "../components/flotas/agregar-saldo/AgregarSaldoScreen";
import { AyudaScreen } from "../components/ayuda/AyudaScreen";

export const DashboardRoutes = () => {
  return (
    <Routes>
      <Route path="datos" element={<DatosScreen />} />
      <Route path="facturas" element={<FacturasScreen />} />
      <Route path="repostajes" element={<RepostajesScreen />} />
      {/* <Route path="estadisticas" element={<EstadisticasScreen />} /> */}
      <Route path="promociones" element={<PromocionesScreen />} />
      <Route path="flotas/mis-flotas" element={<FlotasScreen />} />
      <Route path="nuevo-vehiculo" element={<NuevoVehiculo />} />
      <Route path="gasolineras" element={<GasolineraList />} />
      <Route
        path="datos-facturacion"
        element={<EditarDatosFacturacion />}
      />{" "}
      <Route path="flotas/metodos-pago" element={<MetodosPagoScreen />} />
      <Route path="flotas/nueva-tarjeta" element={<NuevaTarjetaScreen />} />
      {/* <Route path="flotas/estado-operacion" element={<EstadoScreen />} /> */}
      <Route
        path="flotas/estado-operacion/ok"
        element={<EstadoScreen status="ok" />}
      />
      <Route
        path="flotas/estado-operacion/nok"
        element={<EstadoScreen status="nok" />}
      />
      <Route path="flotas/historial" element={<HistorialPagos />} />
      {/* <Route path="flotas/notificaciones" element={<NotificacionesScreen />} /> */}
      <Route
        path="flotas/nuevo-vehiculo"
        element={<RegistroVehiculoScreen />}
      />
      <Route path="flotas/agregar-saldo" element={<AgregarSaldoScreen />} />
      {/* <Route path="flotas/nueva-flota" element={<RegistroFlotaScreen />} /> */}
      <Route path="ayuda" element={<AyudaScreen />} />
      <Route path="/*" element={<Error404Screen />} />
      <Route path="/" element={<DatosScreen />} />
      <Route path="/login" element={<Navigate to="/" />} />
    </Routes>
  );
};
