import { useCallback, useEffect, useState } from "react";
import { Alert, Col, Container, Image, Navbar, Row } from "react-bootstrap";
import petroprix_logo from "../../assets/images/petroprix-logo.svg";
import login_img from "../../assets/images/login-img.svg";
import "./login.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "animate.css";
import { useTranslation } from "react-i18next";
import { ModalInfoVerify } from "./ModalInfoVerify";
import { HelpInfo } from "../ui/HelpInfo";
import axiosApi from "../../axios/axios-api";

export const VerifyEmailScreen = () => {
  const { t } = useTranslation();
  const [modalShowInfo, setModalShowInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [telefono, setTelefono] = useState(t("ayuda.telefono"));
  const [emailAyuda, setEmailAyuda] = useState(t("ayuda.email"));

  const handleShowInfo = () => setModalShowInfo(true);
  const location = useLocation();
  const { email } = location.state || {};
  const navigate = useNavigate();

  useEffect(() => {
    if (!email) {
      navigate("/login");
    }
  }, [email]);

  const fetchInfoContacto = useCallback(async () => {
    try {
      if (process.env.REACT_APP_URL_API) {
        const response = await axiosApi.get("infoPetroprix", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response?.data?.code === 200) {
          let data = response.data.data;
          setTelefono(data.telefono);
          setEmailAyuda(data.email_informacion);
        }
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchInfoContacto();
  }, []);

  return (
    <>
      <Container className=" d-lg-none d-xl-none ">
        <Navbar
          collapseOnSelect
          expand="lg"
          className="menu px-1"
          style={{ display: "flex" }}
        >
          <Navbar.Brand href="/">
            <img
              src={petroprix_logo}
              className="d-inline-block align-top login-page-logo"
              alt={t("login.menu.text")}
            />
          </Navbar.Brand>
          <HelpInfo telefono={telefono} email={email} />
        </Navbar>
      </Container>
      <Container className="content-login-page">
        <Container className="login-header-container">
          <header className="d-none d-lg-block">
            <img
              src={petroprix_logo}
              className="d-inline-block align-left login-page-logo"
              alt={t("login.menu.text")}
            />
          </header>
          <HelpInfo
            className={"d-none d-lg-block"}
            telefono={telefono}
            email={emailAyuda}
          />
        </Container>
        <Container className="login-container py-1">
          <Row className="content-section login-section">
            <Col xs={12} lg={{ span: 12, order: 1 }}>
              <h1>
                {t("login.header.h1")}
                <span className="red-text">{t("login.header.h1.red")}</span>
              </h1>
            </Col>
            <Col
              xs={12}
              lg={{ span: 4, offset: 1, order: 3 }}
              className="d-none d-lg-block"
            >
              <Image src={login_img} fluid="true" className="mt-5" />
            </Col>
            <Col
              xs={12}
              lg={{ span: 4, offset: 2, order: 3 }}
              className="animate__animated animate__fadeInRight"
            >
              <h6 className="mt-5 go-back-link">
                <Link to={`/login`} className="no-decoration">
                  &lt; Volver
                </Link>{" "}
              </h6>
              <h2>{t("verify.h2")}</h2>

              <Alert variant="warning-login">
                <p className="mb-2">{t("verify.alert")}</p>
                <span>{t("verify.alert-2")}</span>
              </Alert>

              <p className="verify-text-info mt-4">
                {t("verify.text.email.info")}
              </p>

              <p className="verify-text-email-data">{email}</p>

              <p className="verify-text-info mt-3">
                {t("verify.text.email.no")}
                <span
                  className="verify-text-info-link"
                  onClick={handleShowInfo}
                >
                  Ver como solucionarlo
                </span>
              </p>
            </Col>
          </Row>

          <ModalInfoVerify
            show={modalShowInfo}
            onHide={() => setModalShowInfo(false)}
          />
        </Container>
      </Container>
    </>
  );
};
