import {
  Button,
  Col,
  Container,
  Dropdown,
  Image,
  Navbar,
  Row,
} from "react-bootstrap";
import error_img from "../../assets/images/error-img.svg";
import petroprix_logo from "../../assets/images/petroprix-logo.svg";
import exit_icon from "../../assets/images/exit-icon.svg";
import config_icon from "../../assets/images/config-icon.svg";
import "./error404.css";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HelpInfo } from "../ui/HelpInfo";
import axiosApi from "../../axios/axios-api";

export const Error404Screen = () => {
  const [logged, setLogged] = useState(false); /* CAMBIAR POR AUTHCONTEXT */

  const { t } = useTranslation();

  const [telefono, setTelefono] = useState(t("ayuda.telefono"));
  const [email, setEmail] = useState(t("ayuda.email"));

  const fetchInfoContacto = useCallback(async () => {
    try {
      if (process.env.REACT_APP_URL_API) {
        const response = await axiosApi.get("infoPetroprix", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response?.data?.code === 200) {
          let data = response.data.data;
          setTelefono(data.telefono);
          setEmail(data.email_informacion);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchInfoContacto();
  }, []);

  return (
    <>
      <Container className=" d-lg-none d-xl-none ">
        <Navbar
          collapseOnSelect
          expand="lg"
          className="menu px-1"
          style={{ display: "flex" }}
        >
          <Navbar.Brand href="/">
            <img
              src={petroprix_logo}
              className="d-inline-block align-top login-page-logo"
              alt={t("menu.logo.text")}
            />
          </Navbar.Brand>
          <HelpInfo telefono={telefono} email={email} />
        </Navbar>
      </Container>
      <Container className="content-error-page">
        <Container className="login-header-container">
          <header className="d-none d-lg-block">
            <img
              src={petroprix_logo}
              className="d-inline-block align-left login-page-logo"
              alt="Petroprix | Zona clientes"
            />
          </header>
          <HelpInfo
            className={"d-none d-lg-block"}
            telefono={telefono}
            email={email}
          />
        </Container>
        <Row>
          <Container className="py-1">
            <Row className="content-section error-section">
              <Col
                xs={12}
                lg={{ span: 5, offset: 1, order: 2 }}
                className="error-text"
              >
                <h2>{t("error404.h2")}</h2>
                <p>
                  {t("error404.info-text.black")}
                  <span className="error-text-red">
                    {t("error404.info-text.red")}
                  </span>
                </p>
              </Col>
              <Col xs={12} lg={{ span: 4, offset: 2, order: 1 }}>
                <Image src={error_img} fluid="true" />
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
    </>
  );
};
